.Testimonial{
    background: #f4f3ff;
    background: linear-gradient(0deg,  rgba(244,243,255,1) 0%,rgba(255,255,255,1)  100%);
    // position: relative;
    width: 100%;
    height: 700px;
    // top: 0%;
    opacity: 1;
    transition: 1s;
    padding-top: 3rem;
    z-index: 1000;
    .TestimonialCard{
        background: #fff;
        border-radius: 35px;
        padding: 0 130px 20px;
        .review-photo.current{
            height: 125px;
            width: 125px;
            border: 5px solid #A178F9;
            border-radius: 50%;
            background-color: rgba(0,0,0,0);
            margin: 73px 0;
            display: inline-block;
            background-image: url('../../assets/images/backgrounds/user.jpg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        .review-photo.two{
            height: 66px;
            width: 66px;
            border-radius: 50%;
            box-shadow: inset 0 0 0 1000px rgba(0,0,0,.6);
            margin: 73px 200px;
            display: inline-block;
            background-image: url('../../assets/images/backgrounds/user_2.jpg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &:hover{
                border: 5px solid #A178F9;
                width: 125px;
                height: 125px;
            }
        }
        .review-photo.three{
            height: 66px;
            width: 66px;
            position: absolute;
            border-radius: 50%;
            box-shadow: inset 0 0 0 1000px rgba(0,0,0,.6);
            margin: -20px 0px 0;
            display: inline-block;
            background-image: url('../../assets/images/backgrounds/user.jpg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &:hover{
                border: 5px solid #A178F9;
                width: 125px;
                height: 125px;
            }
        }
        .review-photo.four{
            height: 66px;
            width: 66px;
            position: absolute;
            border-radius: 50%;
            margin: 17rem 0px 0 18rem;
            display: inline-block;
            background: url('../../assets/images/backgrounds/user_2.jpg');
            box-shadow: inset 0 0 0 1000px rgba(0,0,0,.6);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &:hover{
                border: 5px solid #A178F9;
                width: 125px;
                height: 125px;
            }
        }
        .review-photo.five{
            height: 66px;
            width: 66px;
            position: absolute;
            border-radius: 50%;
            margin: 29rem 0px 0 -17rem;
            display: inline-block;
            background: url('../../assets/images/backgrounds/user_2.jpg');
            box-shadow:inset 0 20px 28px 9px rgba(0, 0, 0, 0.6);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &:hover{
                border: 5px solid #A178F9;
                width: 125px;
                height: 125px;
            }
        }

        h3{
            color: #a178f9;
            font-size: 48px;
            font-weight: bold;
            line-height: 60px;
            letter-spacing: 1px;
        }
        .review-box{
            height: 200px;
            padding-bottom: 3rem;
            p{
                font-size: 24px;
                color: #223051;
                line-height: 39px;
            }
            h5{
                color: #223051;	
                font-family: Avenir;	
                font-size: 24px;	
                font-weight: 900;	
                line-height: 34px;
            }
        }
        .review-box.two, .review-box.three, .review-box.four, .review-box.five{
            display: none;
        }
    }
}