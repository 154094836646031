@mixin screenSize($size) {
    @if $size == large-desktop {
      @media (min-width: 1200px) {
          @content;
      }
    }
    @else if $size == small-desktop {
      @media (min-width: 992px) and (max-width: 1199px) {
          @content;
      }
    }
    @else if $size == tablet {
      @media (min-width: 769px) and (max-width: 991px)  {
          @content;
      }
    }
    @else if $size == ls-phone {
      @media (min-width: 576px) and (max-width: 768px)  {
          @content;
      }
    }
    @else if $size == ss-phone {
      @media (max-width: 575px)  {
          @content;
      }
    }
    @else if $size = xs-phone{
      @media(max-width: 375px){
        @content;
      }
    }
    @else if $size == tab-phone {
      @media (min-width: 576px) and (max-width: 1199px)  {
          @content;
      }
    }
}