@import '../../../assets/scss/mixins/mixins';
.heading.right{
    padding-top: 12rem;
    h3{
        color: #a178f9;
        font-family: 'Poppins', sans-serif;	
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 55px;
        text-align: left;
    }
    p{
        color: #555060;	
        font-size: 1.1rem;	
        font-weight: 300;	
        letter-spacing: 0.43px;	
        line-height: 31px;	
        text-align: left;
    }
    @include screenSize(ss-phone){
        // display: none;
        // width: 50%;
        h3{
            font-size: 1.8rem;
            line-height: 40px;
            text-align: center;
        }
        p{
            font-size: 0.8rem;
            line-height: 24px;
        }
    }
    @include screenSize(tablet){
        // margin-top: 8%;
        h3{
            font-size: 2rem;
            line-height: 40px;
        }
        p{
            font-size: 1rem;
        }
    }
}