@import "../../assets/scss/mixins/mixins";
/****** MAIN BG *****/
.Main-bg {
  background-image: url("../../assets/images/backgrounds/landing_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 700px;
  position: absolute;
  top: 0;
  z-index: 100;

  @include screenSize(ss-phone) {
    background: url("../../assets/images/backgrounds/mobile_main.png");
    width: 100%;
    height: 700px;
    background-position: 0px bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @include screenSize(tablet) {
    background: url("../../assets/images/backgrounds/landing_bg.png");
    width: 100%;
    height: 600px;
    background-size: 100% 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 700px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1366px) {
    height: 780px;
  }

  .banner-text {
    position: absolute;
    width: 100%;
    top: 112px;
    left: 0;
    .line-wrapper {
      .line {
        display: none;
      }
    }
    @include screenSize(ss-phone) {
      top: 50px;
      padding: 0 10px;
      .line-wrapper {
        text-align: center;
        .line {
          // border-left: 2px solid #555060;
          height: 116px;
          display: inline-block;
          margin: 1rem 0;
        }
      }
    }
    h1.lets {
      text-align: center;
      font-size: 180px;
      font-weight: 800;
      letter-spacing: -3px;
      font-family: "Poppins", sans-serif;
      color: #fff;
      line-height: 270px;
      text-shadow: 0 28px 40px rgba(0, 0, 0, 0.09) !important;
      position: relative;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        font-size: 105px;
        margin-bottom: 78px;
        line-height: 94px;
      }
      @media only screen and (min-width: 1024px) and (max-width: 1366px) {
        font-size: calc(20vw - 67%);
        margin-bottom: 78px;
        line-height: 155px;
      }
      @include screenSize(ss-phone) {
        font-size: 66px;
        letter-spacing: -1px;
        color: #a178f9;
        line-height: 75px;
        margin-top: 6.5rem;
        text-align: center;
        font-size: 72px;
        background: radial-gradient(circle at -20%, #8f60f9 70%, #a178f9 0%, #a178f9 80%, #fff 30%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @include screenSize(ls-phone) {
        line-height: 120px;
        font-size: 100px;
        color: #a178f9;
        margin-bottom: 1rem;
      }
      &:before {
        position: absolute;
        content: attr(data-text);
        color: #a178f9;
        background: transparent;
        clip-path: circle(350px at var(--x, -700px) var(--y, -700px));
        @include screenSize(ss-phone) {
          clip-path: none;
        }
        @include screenSize(tablet) {
          display: none;
        }
      }
    }
    @include screenSize(ls-phone) {
      p {
        padding-top: 10vh !important;
        font-size: 20px;
      }
    }
    @include screenSize(desktop) {
      p {
        color: #555060;
        font-size: 26px;
        font-weight: 300;
        letter-spacing: -0.43px;
        line-height: 34px;
        text-align: center;
        margin-top: -2rem;
      }
    }
    @include screenSize(ss-phone) {
      p {
        font-size: 16px;
        line-height: 26px;
        width: 80%;
        margin: auto;
        margin-top: 20px;
      }
    }
  }

  .cursor {
    position: absolute;
    width: 600px;
    height: 600px;
    background: transparent;
    border-radius: 50%;
    top: -40px;
    left: -40px;
    @include screenSize(ss-phone) {
      display: none;
      position: relative;
      left: unset;
      top: unset;
    }
    @include screenSize(ls-phone) {
      display: none !important;
      position: relative;
    }
  }

  .banner-text p.explore-text {
    color: #555060;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 27px;
    text-align: center;
    margin-top: 162px;
    @media only screen and (min-width: 1024px) and (max-width: 1366px) {
      margin-top: 128px;
    }
    @include screenSize(ss-phone) {
      margin-top: 2rem !important;
      font-weight: bolder;
      letter-spacing: 0.8px;
      font-size: 16px;
    }
    @include screenSize(ls-phone) {
      margin-top: -2rem !important;
      font-weight: bolder;
      letter-spacing: 0.8px;
      font-size: 16px;
    }
    @include screenSize(tablet) {
      margin-top: 5.5rem;
    }
  }
  .explore {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 100px;
    span {
      background: #fff;
      padding: 20px;
      border-radius: 50px;
      position: absolute;
      align-items: center;
      bottom: -20%;
      height: 50px;
      width: 50px;
      display: flex;
      text-align: center;
      justify-content: center;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
      i {
        color: #a178f9;
        font-size: 26px;
      }
    }
    @include screenSize(ss-phone) {
      margin-top: 70px;
    }
  }
}

#pin1 {
  height: 500px;
  margin-top: -250px;
}
.Main-bg--sm {
  background-image: url("../../assets/images/backgrounds/phone_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100% !important;
  height: 750px;
  background-position: center;
  margin-top: -250px;
}
// .mobile-app-demo{
//   background-image: url("../../assets/images/guide.gif");
//     background-repeat: no-repeat;
//     // background-size: 28%;
//     background-size: contain;
//     background-position: center center;
//     width: 90%;
//     height: 690px;
//     transition: 0.09s ease-out;
// }
// .demo-section{
//   background: #f4f3ff;
// }
/**** MAIN BG *****/
.test {
  // margin-top: 48%;
  height: 200vh;
  border: 2px solid;
}
.desc {
  display: flex !important;
  align-items: center;
  top: 10%;
  height: auto;
  position: absolute;
  flex-direction: column;
  // background: linear-gradient(100deg, rgba(255, 255, 255, 1) 0%, rgba(244, 243, 255, 1) 100%);
  background: #f4f3ff;
  @include screenSize(ss-phone) {
    width: 100%;
    background: #fff;
  }
  @include screenSize(tablet) {
    background: #fff;
  }
  .Phone {
    background-image: url("../../assets/images/backgrounds/phone_bg.png");
    background-repeat: no-repeat;
    background-size: 35%;
    background-position: center center;
    width: 70%;
    margin-top: -6%;
    height: 1150px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    transition: 0.09s;
    @include screenSize(ss-phone) {
      width: 100%;
      background-size: 75%;
      flex-direction: column;
      margin-top: -18%;
    }
    @include screenSize(tablet) {
      margin-top: -10%;
    }
  }
  .Phone_2 {
    background-image: url("../../assets/images/guide.gif");
    background-repeat: no-repeat;
    background-size: 28%;
    background-position: center center;
    width: 75%;
    margin-top: -6%;
    height: 900px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    transition: 0.09s ease-out;
    @include screenSize(ss-phone) {
      width: 100%;
      background-size: 40%;
      margin-top: -51%;
      flex-direction: column;
      background-size: 25%;
    }
    @include screenSize(tablet) {
      margin-top: -17%;
    }
  }
}
.AboutPhone {
  background-image: url("../../assets/images/backgrounds/about_bg.png");
  background-repeat: no-repeat;
  flex-direction: row;
  transition: 0.6s ease;

  background-size: cover;
  background-position: center 0px;
  height: 500px;
  width: 100%;
  margin-top: -4%;
  margin-left: -10%;
  box-shadow: 0 -129px 61px inset rgba(255, 255, 255, 1);
}
.PhoneOverlay {
  width: 90%;
  background: #fff;
  position: absolute;
  display: none;
  bottom: 22%;
  height: 230px;
  z-index: 10;
  opacity: 0.7;
  transition: 3s ease-in;
}
.AboutPhone2 {
  background-image: url("../../assets/images/backgrounds/about_1.png");
  background-repeat: no-repeat;
  flex-direction: row;
  transition: 0.6s ease;

  background-size: cover;
  background-position: center 0px;
  height: 500px;
  width: 100%;
  margin-top: -4%;
  margin-left: -10%;
  box-shadow: 0 -129px 61px inset rgba(255, 255, 255, 1);
}
.AboutPhone3 {
  background-image: url("../../assets/images/backgrounds/about_2.png");
  background-repeat: no-repeat;
  flex-direction: row;
  transition: 0.6s ease;

  background-size: cover;
  background-position: center 0px;
  height: 500px;
  width: 100%;
  margin-top: -4%;
  margin-left: -10%;
  box-shadow: 0 -129px 61px inset rgba(255, 255, 255, 1);
}
.TourBG {
  background-image: url("../../assets/images/impact.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 500px;
  transition: 1s ease-in;
  margin-top: 2%;
  position: relative;
  border-radius: 50px;
}
.tour-overlay {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.98) 1%,
    rgba(255, 255, 255, 0.98) 5%,
    rgba(255, 255, 255, 0.99) 62%,
    rgba(255, 255, 255, 0.99) 72%,
    rgba(207, 187, 252, 1) 100%
  );
  width: 85%;
  height: 550px;
  border-radius: 50px;
  position: absolute;
  box-shadow: 10px 54px 20px 0px rgba(0, 0, 0, 0.06);
  left: 15%;
  top: -1%;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//***** MOBILE SCREENSIZE 575PX *****//
@include screenSize(ss-phone) {
  .Tour {
    transition: 2s ease-in-out;
    padding-bottom: 3rem;
    height: unset;
  }
  .TourBG-m {
    // background-image: attr(data-src url);
    background-repeat: no-repeat;
    background-position: center 100%;
    height: 250px;
    width: 160px;
    border-radius: 30px;
    transition: 2s ease;
    margin-top: -5%;
    position: relative;
  }
  .TourOverlay-m {
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.98) 1%,
      rgba(255, 255, 255, 0.98) 5%,
      rgba(255, 255, 255, 0.99) 62%,
      rgba(255, 255, 255, 0.99) 72%,
      rgba(207, 187, 252, 1) 100%
    );
    width: 170px;
    height: 230px;
    border-radius: 40px;
    position: absolute;
    box-shadow: 10px 30px 20px 0px rgba(0, 0, 0, 0.18);
    left: -9%;
    top: 9%;
  }
  .TourOverlay-m.switch {
    left: -3% !important;
    box-shadow: -18px 30px 20px 0px rgba(0, 0, 0, 0.18);
    right: 12%;
    background: linear-gradient(
      -135deg,
      rgba(255, 255, 255, 0.98) 1%,
      rgba(255, 255, 255, 0.98) 5%,
      rgba(255, 255, 255, 0.99) 62%,
      rgba(255, 255, 255, 0.99) 72%,
      rgba(207, 187, 252, 1) 100%
    );
  }
  .mid {
    .TourBG-m {
      position: absolute;
      right: 10px;
      top: -22px;
    }
    .TourOverlay-m {
      left: 62%;
      right: 0;
      width: 160px;
      position: relative;
    }
  }
  .Testimonial {
    display: none !important;
  }
}
//***** MOBILE SCREENSIZE 768PX *****//
@include screenSize(ls-phone) {
  .Tour {
    width: 100%;
    height: auto;
    padding-bottom: 80px;
  }
  .TourBG-m {
    background-image: url("../../assets/images/backgrounds/bridge.png");
    background-repeat: no-repeat;
    background-position: center center;
    height: 440px;
    width: 300px;
    border-radius: 30px;
    transition: 2s ease;
    margin-top: -5%;
    position: relative;
  }
  .TourOverlay-m {
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.98) 1%,
      rgba(255, 255, 255, 0.98) 5%,
      rgba(255, 255, 255, 0.99) 62%,
      rgba(255, 255, 255, 0.99) 72%,
      rgba(207, 187, 252, 1) 100%
    );
    width: 250px;
    height: 370px;
    border-radius: 50px;
    position: absolute;
    box-shadow: 10px 54px 20px 0px rgba(0, 0, 0, 0.06);
    left: 15%;
    // left: 27%;
    top: 16%;
  }
  .mid {
    .TourBG-m {
      position: absolute;
      right: 20px;
      top: -4.5rem;
    }
    .TourOverlay-m {
      left: 2%;
      right: 0;
      width: 300px;
      position: relative;
    }
  }
  .Testimonial {
    display: none !important;
    z-index: 10;
  }
}
@include screenSize(ls-phone) {
  .Testimonial {
    display: none !important;
  }
}
