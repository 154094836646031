@import '../../assets/scss/mixins/mixins';
.Footer{
    background: #fff;
    width: 100%;
    // position: absolute;
    height: 100vh; 
    top: 0%;
    opacity: 1;
    transition: 1s;
    padding-top: 7rem;
    padding-bottom: 6rem;
    z-index: 1000;
    .footer-logo{
        color: #555060;   
    }
    @include screenSize(ss-phone){
        .order-xs-1{
            order: 1;
        }
        .order-xs-2{
            order: 2;
        }
        opacity: 1;
        position: relative;
        height: unset;
        padding-bottom: 2rem;
        .row{
            text-align: center;
        }
    }
    @include screenSize(ls-phone){
        opacity: 1;
        position: relative;
        height: unset;
        padding-bottom: 2rem;
        
    }
    .copyright{
        color: rgba(85, 80, 96, .5);
        margin-top: 8rem;
        font-size: 24px;	
        line-height: 48px;
        @include screenSize(ss-phone){
            font-size: 14px;
            margin-top: 3.8rem;
        }
        @include screenSize(ls-phone){
            font-size: 14px;
            margin-top: 3.8rem;
        }
    }
    ul.footer-nav{
        margin-top: 179px;
        li{
            list-style-type: none;
            color: #555060;
            font-size: 24px;
            font-family: Mont;
            margin-bottom: 40px;
            &:hover{
                color: #8D62EA;
                list-style: disc;
            }
        }
        @include screenSize(ss-phone){
            margin-top: 3rem;
            li{
                font-size: 14px;
            }
        }
        @include screenSize(tablet){
            margin-top: 3rem;
            li{
                font-size: 14px;
            }
        }
    }
}