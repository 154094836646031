@import "../../../assets/scss/mixins/mixins";

.Phone {
  padding-top: 3rem;
  background: #f4f3ff;
  padding-bottom: 3rem;
  height: 120vh;
  .mobile-app-demo {
    background-image: url("../../../assets/images/guide.gif");
    background-repeat: no-repeat;
    // background-size: 28%;
    background-size: 70% 70%;
    background-position: center center;
    width: 100%;
    height: 920px;
    transition: 0.09s ease-out;
    @media only screen and (min-width: 1024px) and (max-width: 1366px) {
      background-size: 80% 80%;
      height: 668px;
    }
  }
}
#pin2{
  // padding-top: -3rem;
  // height: 650px;
}

#pin1{
  height: 120vh !important;
  background: #f4f3ff;
  // position: relative;
  @media only screen and (min-width: 1024px) and (max-width: 1366px) {
    height: 1024px !important;
  }
  @include screenSize(ss-phone) {
    height: 1024px !important;
  }
}
