@import '../../assets/scss/mixins/mixins';
.Tour{
    background: #ffffff;
    // position: absolute;
    // width: 100%;
    // height: 100vh;
    // top: 0%;
    // opacity: 0;
    // transition: 1s;
    padding-top: 3rem;
    // z-index: 1000;
    @include screenSize(ss-phone){
        top: -5%;
        opacity: 1;
        position: unset;
    }

    .mobile{
        display: none;
    }
    .tour-info{
        
            .carousel-item {
                background-image: none;
                box-shadow: none
            }
        
        p{
            color: #555060;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 1.5px;
            line-height: 35px;
            position: absolute;
            margin-top: 14rem;
            transform: translateX(10%);
            width: 60%;
            background: #fff;;
            
        }
        .info-text-one{
            position: absolute;
            transform: translateX(51%);
            opacity: 1;
            height: auto;
            background: #fff;
        }
        .info-text-two{
            position: absolute;
            transform: translateX(160%);
            // border: 2px solid;
            transition: 2s ease-in;
            opacity:1;
            display: none;
            background: #fff;
        }
        .info-text-three{
            position: absolute;
            transform: translateX(160%);
            // border: 2px solid;
            transition: 3s ease-in;
            opacity: 1;
            display: none;
            background: #fff;
        }
    }
    
    @include screenSize(ss-phone){
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
        .Tour-text{
            color: #555060;
            font-family: Mont;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.8px;
            line-height: 21px;
            @include screenSize(tablet){
                text-align: center;
            }
        }
        
        .mt--5e{
            margin-top: 4.5rem;
        }
    }
    @include screenSize(ls-phone){
        opacity: 1;
        position: relative;
        height: 220vh;
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
        .mt--5e{
            margin-top: 4.5rem;
        }
    }
    .Tour-text{
        color: #555060;
        font-family: Mont;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.8px;
        line-height: 21px;
        @include screenSize(ls-phone){
            text-align: center;
        }
    }
}
.self-center{
    align-self: center;
}
.ml--2{
    margin-left: -20px;
}
.mr--2{
    margin-right: 0px;
    @include screenSize(tablet){
        margin-right: 2px;
    }
}