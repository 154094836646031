.AboutCard{
    opacity: 0.04;
    transition:0.2s ease-in;
    icon{
        background: #fff;
        width: 90px;
        height: 90px;
        border-radius: 50%;
    }
    h4{
        color: #555060;
        font-size: 30px;
        letter-spacing: 1.5px;
        line-height: 40px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    p{
        color: #555060;	
        font-size: 18px;
        letter-spacing: 0.43px;	
        line-height: 25px;
    }
}