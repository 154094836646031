@import "../../assets/scss/base/variables";
@import "../../assets/scss/mixins/mixins";

.navbar {
  .navbar-brand.mobile {
    display: none;
    @include screenSize(ss-phone) {
      display: inline-block;
    }
    @include screenSize(ls-phone) {
      display: inline-block;
    }
  }
}
.no-shadow {
  background: transparent;
  box-shadow: none !important;
}
.shadow {
  background-image: url("../../assets/images/backgrounds/landing_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 285%;
  box-shadow: 0 3px 0 rbga(0, 0, 0, 0.6) !important;
}
.navbar-nav {
  z-index: 1000;
  .nav-link {
    i {
      font-size: 20px;
      margin-right: 3.3rem;
      color: $grey;
      &:hover {
        color: $sheltr;
      }
    }
  }

  button {
    box-shadow: none !important;
    text-transform: none !important;
    @include screenSize(ss-phone) {
      border: 1px solid #a178f9;
    }
    @include screenSize(ls-phone) {
      border: 1px solid #a178f9;
      width: 40%;
    }
  }

  .logo {
    height: 38px;
    width: 136px;
  }
  .navbar-brand {
    @include screenSize(ss-phone) {
      display: none;
    }
    @include screenSize(ls-phone) {
      display: none;
    }
  }
}
.social-header{
    @include screenSize(ss-phone){
        flex-direction: row;
        margin: 1.5rem 0;
    }
    @include screenSize(ls-phone){
      flex-direction: row;
      margin: 1.5rem 0;
  }
}
