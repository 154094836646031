@import '../../assets/scss/mixins/mixins';
.Subscribe{
    width: 100%;
    background: #f4f3ff;
    width: 100%;
    height: 100vh;
    transition: 1s;
    padding-top: 7rem;
    padding-bottom: 2rem;
    // position: absolute;
    // top: 0%;
    z-index: 10;
    opacity: 1;
    @include screenSize(ss-phone){
        top: -9%;
        position: relative;
        opacity: 1;
        height: auto;
    }
    @include screenSize(ls-phone){
        top: -9%;
        position: relative;
        opacity: 1;

    }
    
    h1{
        color: #8D62EA;
       font-size: 48px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 60px;
        text-align: center;
        margin-bottom: 4.8rem;
        br{
            display: none;
        }
        @include screenSize(ss-phone){
            font-size: 32px;
            line-height: 42px;
            margin-bottom: 2rem;
            letter-spacing: 0.58px;
            br{
                display: block;
            }
        }
        @include screenSize(tablet){
            font-size: 28px;
            line-height: 38px;
            margin-bottom: 2rem;
            letter-spacing: 0.58px;
        }
    }
    h3{
        color: #555060;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1.2px;
        line-height: 35px;
        text-shadow: 0 2px 4px  rgba(0,0,0,0);
        text-align: center;
        @include screenSize(ss-phone){
            font-size: 14px;
            line-height: 21px;
        }
        @include screenSize(ls-phone){
            font-size: 14px;
            line-height: 21px;
        }
    }
    form{
        margin-top: 6.8rem;
        @include screenSize(ss-phone){
            justify-content: center;
            padding: 0 1rem;
        }
    }
    input.form-control{
        width: 65%;
        margin-right: 2rem;
        background: transparent;
        border: none;
        border-bottom: 1.5px solid #AC9FCB;
        color: rgba(97,75,148,0.49);
        font-family: Mont;
        font-size: 21px;
        font-weight: bold;
        line-height: 28px;
        text-align: center;
        &:focus{
            border-color: #a178f9;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(161, 120, 249, 0.4);
        }
        @include screenSize(ss-phone){
            width: 100%;
            margin: auto;
            margin-bottom: 2.5rem;
            font-size: 12px;
            line-height: 16px;
        }
        @include screenSize(tablet){
            width: 60%;
            margin: auto;
            margin-bottom: 0.5rem;
            font-size: 14px;
            line-height: 16px;
        }
    }
    .Subscribe-btn{
        background: #a178f9;
        color: #fff;
        border-radius: 50px;
        border: none;
        width: 200px;
        text-align: left;
        padding: 0 5px;
        font-weight: bold;
        img{
            margin-right: 10px;
        }
    }
    .mobile-links{
        margin-top: 6.8rem;
        text-align: center;
        
        a{
            display: inline-block;
            img{
                width: 83%;
            }
        }
        @include screenSize(ss-phone){
            margin-top: 3.8rem;
            a{
                margin-right: 0 !important;
                margin-bottom: 2rem;
            }
        }
        @include screenSize(ls-phone){
            margin-top: 3.8rem;
            a{
                margin-right: 2rem !important;
                margin-bottom: 2rem;
            }
        }
    }

    .toast {
        position: absolute;
    }
}
.mr-5e{
    margin-right: 8rem;
}