@import "../../assets/scss/mixins/mixins";
.About {
  background: #f4f3ff;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(244, 243, 255, 1) 100%);
  // position: absolute;
  width: 100%;
  height: 100vh;
  // top: 0%;
  // opacity: 0;
  // transition: 1s;
  margin-top: 3rem;
  padding-top: 3rem;
  z-index: 1000;
  .about-details {
    margin-top: 5rem;
  }
}
.AboutWrapper {
  @include screenSize(ss-phone) {
    background: #f4f3ff;
    background: linear-gradient(0deg, rgba(244, 243, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    width: 100%;
    transition: 1s;
    padding-top: 1.5rem;
  }

  @include screenSize(tablet) {
    background: #f4f3ff;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1)  0%,  rgba(244, 243, 255, 1) 100%);
    width: 100%;
    transition: 1s;
    padding-top: 0;
    margin-top: 4rem;
  }
}
.AboutMobile1{
  background:#f4f3ff;
  height: auto;
  padding-bottom: 10vh;
 
  @include screenSize(ls-phone){
    // height: 90vh;
    // margin-top: 80vh;
    // padding-top: 2rem;
  }
}
.carousel-inner {
  // margin-top: 5rem;
  background: #fff;
  @include screenSize(ss-phone) {
    margin-top: 0;
  }
  @include screenSize(ls-phone){
    padding-top: 2rem;
  }
  .carousel-item:nth-child(1) {
    background-image: url("../../assets/images/backgrounds/about_2x.png");
    background-repeat: no-repeat;
    flex-direction: row;
    transition: 0.6s ease;
    background-size: contain;
    background-position: center 0px;
    height: 500px;
    width: 100%;
    box-shadow: 0 -15rem 61px inset #f4f3ff;
 }
 .carousel-item:nth-child(2) {
  background-image: url("../../assets/images/backgrounds/about_1.png");
  background-repeat: no-repeat;
  flex-direction: row;
  transition: 0.6s ease;
  background-size: contain;
  background-position: center 0px;
  height: 500px;
  width: 100%;
  box-shadow: 0 -15rem 61px inset #f4f3ff;
}
.carousel-item:nth-child(3) {
  background-image: url("../../assets/images/backgrounds/about_bg.png");
  background-repeat: no-repeat;
  flex-direction: row;
  transition: 0.6s ease;
  background-size: contain;
  background-position: center 0px;
  height: 500px;
  width: 100%;
  box-shadow: 0 -15rem 61px inset #f4f3ff;
}
  @include screenSize(tablet) {
    margin-top: 3rem;
  }
}
.carousel-indicators {
  bottom: -8%;
  @include screenSize(tablet) {
    bottom: -11%;
  }
  li {
    background-color: #a178f9;
  }
}

.carousel-caption{
  h3 {
    color: #555060;
    font-size: 18px;
    letter-spacing: 0.9px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    letter-spacing: 0.4px;
    color: #555060;
  }
}

.carousel-caption1 {
  position: absolute;
  display: block !important;
  width: 75%;
  margin: auto;
  background-image: url("../../assets/images/about_icon.svg");
  background-size: 20%;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 4rem;
  margin-top: 1rem;
  @include screenSize(tablet) {
    padding-top: 5rem;
  }
  
}

.carousel-caption2 {
  position: absolute;
  display: block !important;
  width: 75%;
  margin: auto;
  background-image: url("../../assets/images/about_icon_2.png");
  background-size: 20%;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 4rem;
  margin-top: 1rem;
  @include screenSize(tablet) {
    padding-top: 5rem;
  }
}
.carousel-caption3 {
  position: absolute;
  display: block !important;
  width: 75%;
  margin: auto;
  background-image: url("../../assets/images/icon.png");
  background-size: 20%;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 4rem;
  margin-top: 1rem;
  @include screenSize(tablet) {
    padding-top: 5rem;
  }
}

.AboutMobile1 {
  
}
.AboutMobile2 {
  @include screenSize(ss-phone) {
    width: 100%;
    top: 13%;
    transition: 1s;
    padding-top: 6rem;
  }
  @include screenSize(tablet) {
    margin-top: 4rem;
  }
}
.MobileCard {
  background: #fff;
  border-radius: 5rem;
  margin-top: 3rem;
  padding-left: 4rem;
  margin: 1rem;
  @include screenSize(ls-phone) {
    margin: 2rem 3rem;
  }
}
.about-icon {
  margin-top: 27px;
  @include screenSize(ls-phone) {
    // margin-top: 3.5rem;
    img {
      width: 30% !important;
    }
  }
}
.content {
  margin-top: 7rem;
  margin-right: 2rem;
  padding-bottom: 3rem;
  h3 {
    color: #8f60f9;
    font-family: Poppins;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.58px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 35px;
  }
  p {
    color: #555060;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.3px;
    line-height: 21px;
    text-align: center;
  }
}
.chip-bg {
  background-image: url("../../assets/images/backgrounds/CHIP.svg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 150px;
  @include screenSize(ls-phone) {
    height: 250px;
    background-position: top right;
    background-size: contain;
  }
}
.text-overlay {
  width: 100%;
  background: #fff;
  position: absolute;
  bottom: 21%;
  height: 340px;
  z-index: 10;
  opacity: 0.7;
  transition: 3s ease-in;
}