@import '../../../assets/scss/mixins/mixins';
.heading.left{
    padding-top: 12rem;
    h3{
        color: #a178f9;
        font-family: 'Poppins', sans-serif;	
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 55px;
        text-align: right;
    }
    p{
        color: #555060;	
        font-size: 1.1rem;	
        font-weight: 300;	
        letter-spacing: 0.43px;	
        line-height: 31px;	
        text-align: right;
    }
    @include screenSize(ss-phone){
        width: 100%;
        margin-left: 1%;
        display: none;
        border: 2px solid;
        h3{
            font-size: 1.8rem;
            line-height: 40px;
        }
        p{
            font-size: 0.8rem;
            line-height: 24px;
        }
    }
    @include screenSize(tablet){
        // margin-top: 7%;
        // width: 50%;
        // margin-left: -17%;
        h3{
            font-size: 2rem;
            line-height: 40px;
        }
        p{
            font-size: 1rem;
        }
    }
}